import React from "react";
import { Modal, Form, } from 'semantic-ui-react';
import { useSelector, useDispatch } from "react-redux";
import "./LoginModal.css";
import { setLoginData } from "../../../actions/index";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { useState } from "react";
import { useEffect } from "react";

const LoginModal = props => {
  const { open, onClose, lang, Auth } = props;
  const [fail_message, setFail_message] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenForgotPwd, setIsOpenForgotPwd] = useState(false);
  const successFunction = useSelector(state => state.modalAction.afterLoginFunction);
  const dispatch = useDispatch();

  const init = () => {
    setEmail("");
    setFail_message("");
    setPassword("");
    setIsOpenForgotPwd(false);
  };

  const checkFields = (email, password) => {
    if (email === "") {
      setFail_message(lang.get('Login-message-error-email'));
      return false;
    } else if (password === "") {
      setFail_message(lang.get('Login-message-error-password'));
      return false;
    }
    return true;
  };

  const afterLogin = () => {
    setIsLoading(false);
    dispatch(setLoginData(Auth.getToken(), Auth.getProfile()));
    if (successFunction) successFunction();
    onClose();
    // window.scrollTo(0, 0);
    // window.location.reload();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (checkFields(email, password)) {
      let result = await Auth.userlogin(email, password).catch(err => {
        console.log(err);
      });
      const { jwt, message, token } = result;
      if (jwt != null) {
        await Auth.getUserProfileFromJWT(jwt);
        afterLogin();
      } else if (message === "API_ERROR.EMAIL_OR_PASSWORD_INVAILD") {
        setIsLoading(false);
        setFail_message(lang.get('Login-message-error-invaild'));
      } else if (message === "API_ERROR.LOCK_ACCOUNT") {
        setIsLoading(false);
        setFail_message(lang.get('Login-message-error-lock'));
      } else if (token) {
        // debug mode
        afterLogin();
      } else {
        setIsLoading(false);
        password && setFail_message(password);
        email && setFail_message(email);
      };
    } else {
      setIsLoading(false);
    }
  };

  useEffect(init, [open]);

  return (
    <Modal
      centered={false}
      open={open}
      onClose={onClose}
      style={{
        maxWidth: "500px"
      }}
    >
      <div className="__login_modal __custom_modal">
        <div className="__login_modal_title">{lang.get("Login-title")}</div>
        <div className="__login_modal_description">
          {lang.get("Login-descript")}
        </div>
        <div className="__login_modal_form">
          <Form onSubmit={handleFormSubmit} error={false}>
            <div className="__login_modal_form_field">
              <input
                autoComplete='on'
                className="__login_modal_form_email"
                name="email"
                type="text"
                placeholder={lang.get("Login-email-hint")}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="__login_modal_form_field">
              <input
                autoComplete='on'
                className="__login_modal_form_password"
                name="password"
                type="password"
                placeholder={lang.get("Login-password-hint")}
                onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="__login_modal_form_forgot_password_content">
              <div
                className="__login_modal_form_forgot_password"
                onClick={() => setIsOpenForgotPwd(true)}>
                {lang.get('Login-btn-forgotPwd')}
              </div>
            </div>
            {fail_message !== "" && (
              <div className="__login_modal_form_fail_message">
                {fail_message}
              </div>
            )}
            <button
              className="__login_modal_form_button orangeButton"
              type="submit"
            //disabled={isLoading}
            >
              {isLoading ? "Loading..." : lang.get("Login-btn-txt")}
            </button>
          </Form>
        </div>
        <ForgotPasswordModal
          open={isOpenForgotPwd}
          onClose={() => setIsOpenForgotPwd(false)}
          lang={lang}
          Auth={Auth}
        />
      </div>
    </Modal>
  );
};

export default LoginModal;
