import { combineReducers } from "redux";
import * as actionTypes from "../actions/types";
import { langState, pageState, traceState } from "../constants/model";

const initialLanguageState = {
  currentLanguage: langState.get("lang"),
  languageTable: langState.getIn(["langs", langState.get("lang")])
};

const lang_reducer = (state = initialLanguageState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return {
        currentLanguage: action.payload.currentLanguage,
        languageTable: langState.getIn([
          "langs",
          action.payload.currentLanguage
        ])
      };
    default:
      return state;
  }
};

const initialSideMenuState = {
  isOpen: false
};

const sideMenu_reducer = (state = initialSideMenuState, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDEMENU:
      return {
        isOpen: action.payload.isOpen
      };
    default:
      return state;
  }
};

const initialAuthStatus = {
  currentJWT: "",
  profile: ""
};

const auth_reducer = (state = initialAuthStatus, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        currentJWT: action.payload.jwt,
        profile: action.payload.profile
      };
    default:
      return state;
  }
};

const initialPage = pageState;

const page_reducer = (state = initialPage, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE:
      return {
        page: action.payload.page
      };
    default:
      return state;
  }
};

const initialTrace = traceState;

const trace_reducer = (state = initialTrace, action) => {
  switch (action.type) {
    case actionTypes.SET_TRACE:
      let { trace } = Object.assign({}, state);
      trace.push(action.payload.trace);
      return {
        trace,
      };
    default:
      return state;
  }
}

const initialTraceState = {
  traceData: []
};

const traceData_reducer = (state = initialTraceState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRACEDATA:
      return {
        traceData: action.payload.trace
      };
    default:
      return state;
  }
};

const initialModalState = {
  isLoginModalOpen: false,
  afterLoginFunction: null,
  isForgotPwdModalOpen: false,
  isLogoutModalOpen: false,
  isConfirmModalOpen: false,
  confirmModalMessage: ''
};

const modal_reducer = (state = initialModalState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN_MODAL:
      return Object.assign({}, state, { isLoginModalOpen: action.payload.isOpen, afterLoginFunction: action.payload.loginFun });
    case actionTypes.SET_LOGOUT_MODAL:
      return Object.assign({}, state, { isLogoutModalOpen: action.payload.isOpen });
    case actionTypes.SET_CONFIRM_MODAL:
      return Object.assign({}, state, {
        isConfirmModalOpen: action.payload.isOpen,
        confirmModalMessage: action.payload.message
      });
    default:
      return state;
  }
};


const rootReducer = combineReducers({
  langAction: lang_reducer,
  sideMenuAction: sideMenu_reducer,
  authAction: auth_reducer,
  pageAction: page_reducer,
  traceAction: trace_reducer,
  traceDataAction: traceData_reducer,
  modalAction: modal_reducer
});

export default rootReducer;
