import React, { useState } from 'react'
import { Form, Modal } from 'semantic-ui-react'
import './ForgotPasswordModal.css';
const ForgotPasswordModal = props => {
    const { open, onClose, lang, Auth, onSuccess } = props;
    const [fail_message, setFail_message] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const init = () => {
        setEmail("");
        setFail_message("");
        setIsSuccess(false);
        setIsLoading(false);
    };

    const onCloseEvent = () => {
        init();
        onClose();
    };

    const onSubmit = async e => {
        e.preventDefault();
        if (!isSuccess) {
            setIsLoading(true);
            setFail_message('');
            let result = await Auth.sendForgetPasswordMail(email).catch(err => {
                console.log(err);
            });
            if (result.message === 'API_ERROR.NO_ACCOUNT') {
                setFail_message(lang.get('ForgotPwd-message-error-inexist'));
            } else if (result.message === 'API_ERROR.ARGUMENT_ERROR') {
                setFail_message(lang.get('ForgotPwd-message-error-invaild'));
            } else if (result.count === 1) {
                setIsSuccess(true);
            };
            setIsLoading(false);
        } else {
            onCloseEvent();
        };
    };

    let msgClassName = fail_message !== '' ? "__forgotPwd_modal_message" : '__forgotPwd_modal_message fail_message';

    let btnTxt = lang.get('ForgotPwd-submit');
    if (isLoading) {
        btnTxt = 'Loading...';
    };
    if (isSuccess) {
        btnTxt = lang.get('ForgotPwd-close');
    };
    return (
        <Modal
            className="__forgotPwd_modal"
            open={open}
            onOpen={init}
            onClose={onCloseEvent}
            style={{
                textAlign: "center",
                maxWidth: "460px"
            }}
        >
            <Modal.Header>{lang.get('ForgotPwd-modal-header')}</Modal.Header>
            <Modal.Content>
                <div className="__login_modal_form_forgot_password_description">
                    {isSuccess ? lang.get('ForgotPwd-message-success') : lang.get('ForgotPwd-description')}
                </div>
                <Form onSubmit={onSubmit}>
                    {!isSuccess &&
                        <>
                            <input
                                className="__login_modal_form_forgot_password_email"
                                type="text"
                                name="email"
                                value={email}
                                placeholder={lang.get("Login-email-hint")}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <div className={msgClassName}>
                                {fail_message}
                            </div>
                        </>
                    }
                    <button type="submit" className="__forgotPwd_modal_button orangeButton">
                        {btnTxt}
                    </button>
                </Form>
            </Modal.Content>
        </Modal>
    )
}

export default ForgotPasswordModal;
