import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setLanguage, setPage } from '../../../actions';
import { Menu, Segment, MenuItem, Image, Sidebar, Icon } from "semantic-ui-react";

import AuthManager from "../../../auth/AuthManager";

import LangDropDown from './LangDropDown';
import MenuList from './MenuList';

import { langs } from "../routes";
import { justifyNameToPath } from '../../../utils';

import logo from "../../../images/logo.svg";
import contactIcon from "../../../images/header/header_contact_icon.png";

import './Header.css';
import MemberDropDown from './MemberDropDown';

// old menu
// const menuItems = [
//     { name: 'What is WiCanvas', path: '', auth: false },
//     { name: 'Products', path: 'products', auth: false },
//     { name: 'Contact', path: 'contact', auth: false },
//     { name: 'Video', path: 'video', auth: false },
//     { name: 'Support', path: 'support', auth: true },
//     { name: 'Scenario', path: 'scenario', auth: true }
// ]

const Auth = new AuthManager();
const Header = props => {
    const dispatch = useDispatch();
    const { isMobile, lang, currentPage, openLoginModal, openLogoutModal, handleSidebarHide, handleToggle, isSidebarOpened } = props;
    const langPath = lang.get("lang-path");
    const [currentLangData, setCurrentLangData] = useState(langs.find(lang => langPath === lang.langPath));
    const menuStyle = isMobile
        ? {
            backgroundColor: "#d2d2d2",
            fontSize: 0
        }
        : {};
    const menuItems = [
        { name: "Home", auth: false, isProduct: false, forTablet: true },
        { name: 'Canvas', auth: false, isProduct: true },
        { name: 'LED Wall', auth: false, isProduct: true },
        { name: 'Borderless', auth: false, isProduct: true },
        { name: 'Office', auth: false, isProduct: true },
        { name: 'Outdoor', auth: false, isProduct: true },
        { name: 'Kiosk', auth: false, isProduct: true },
        { name: 'CMS', auth: false, isProduct: true },
        { name: 'Cases', auth: false, isProduct: false },
        { name: 'Contact', auth: false, isProduct: false },
        { name: "About Us", auth: false, isProduct: false, forMobile: true },
        { name: 'Support', auth: true, isProduct: false },
    ]
        .filter((i, n) => {
            // if (currentLangData.langName === 'English' && i.name === 'LED Wall') return false;
            if (window.innerWidth >= 768 && i.forMobile) return false;
            if (window.innerWidth > 1120 && i.forTablet) return false;
            if (langPath === '/' && i.forJp) return false;
            return Auth.isLogin() ? true : i.auth === false;
        })
        .map(i => {
            if (i.name === 'Home') i.path = '';
            if (i.name === 'Kiosk') i.path = justifyNameToPath(`${i.name} 43`);
            else i.path = justifyNameToPath(i.name);
            i.jpName = lang.get(`nav-item-${i.name.replace(/\s+/g, "").toLowerCase()}`);
            return i;
        });
    return (
        <>
            <div
                id="desktop__segment__group"
                style={menuStyle}
            >
                <h1 className="logo">
                    <span>Digital Signage | Professional Display | WiCanvas</span>
                    <Link style={{ borderRadius: 0 }} to={langPath} onClick={() => dispatch(setPage(""))}>
                        <Image src={logo} alt='logo' />
                    </Link>
                </h1>
                {
                    isMobile
                        ? (
                            <div className={isSidebarOpened ? "mobile_sidebar_btn active" : "mobile_sidebar_btn"} onClick={() => handleToggle(!isSidebarOpened)}>
                                <div className="mobile_sidebar_btn_icon" />
                                <div className="mobile_sidebar_btn_icon" />
                                <div className="mobile_sidebar_btn_icon" />
                            </div>
                        )
                        : (
                            <>
                                <div className="desdesktop__segment">
                                    <Menu className="desdesktop_segment_mainmenu" secondary borderless>
                                        <MenuList
                                            currentPage={currentPage}
                                            lang={lang}
                                            setPage={path => dispatch(setPage(path))}
                                            langPath={langPath}
                                            menuItems={menuItems.filter(i => i.name !== 'Contact')}
                                        />
                                    </Menu>
                                </div>
                                <div className="desdesktop__right_segment">
                                    <Menu secondary borderless floated="right">
                                        {/* <MenuItem
                                            as={Link}
                                            style={{ padding: "0 5px" }}
                                            to={`${langPath}contact`}
                                            onClick={() => dispatch(setPage("contact"))}
                                        >
                                            <Image src={contactIcon} />
                                        </MenuItem>
                                        <LangDropDown
                                            lang={lang}
                                            langs={langs}
                                            isMobile={isMobile}
                                            setLanguage={lang => dispatch(setLanguage(lang))}
                                            setCurrentLangData={setCurrentLangData}
                                            handleSidebarHide={handleSidebarHide}
                                        /> */}
                                        {
                                            !Auth.isLogin() ? (
                                                <MenuItem
                                                    as="a"
                                                    onClick={openLoginModal}
                                                    style={{ padding: ".78571429em .3em" }}
                                                    fitted
                                                >
                                                    {lang.get("nav-item-login")}
                                                </MenuItem>
                                            ) : (
                                                <div
                                                    style={{ padding: ".78571429em .3em" }}
                                                >
                                                    Hi, {Auth.getProfile()}
                                                </div>
                                            )
                                        }
                                        {
                                            (
                                                <MemberDropDown
                                                    profile={Auth.getProfile()}
                                                    isMobile={isMobile}
                                                    logoutTxt={lang.get("nav-item-logout")}
                                                    openLogoutModal={openLogoutModal}
                                                    lang={lang}
                                                    langs={langs}
                                                    setCurrentLangData={setCurrentLangData}
                                                    handleSidebarHide={handleSidebarHide}
                                                />
                                            )
                                        }
                                    </Menu>
                                </div>
                            </>
                        )
                }
            </div>
            {
                isMobile && (
                    <Sidebar as={Menu} animation="overlay" inverted onHide={handleSidebarHide} vertical visible={isSidebarOpened}>
                        <MenuList
                            isMobile={isMobile}
                            currentPage={currentPage}
                            lang={lang}
                            setPage={path => dispatch(setPage(path))}
                            langPath={langPath}
                            menuItems={menuItems}
                            handleSidebarHide={handleSidebarHide}
                        />
                        <MenuItem
                            as="a"
                            onClick={() => {
                                if (!Auth.isLogin()) {
                                    openLoginModal();
                                } else {
                                    openLogoutModal();
                                };
                                handleSidebarHide();
                            }}
                        >
                            {!Auth.isLogin() ? lang.get("nav-item-login") : Auth.getProfile()}
                        </MenuItem>
                        <Menu.Item>
                            <LangDropDown
                                lang={lang}
                                langs={langs}
                                isMobile={isMobile}
                                setLanguage={lang => dispatch(setLanguage(lang))}
                                setCurrentLangData={setCurrentLangData}
                                handleSidebarHide={handleSidebarHide}
                            />
                        </Menu.Item>
                    </Sidebar>
                )
            }
        </>
    )
}

export default Header;