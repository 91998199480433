import * as actionTypes from "./types";

export const setLanguage = language => {
  return {
    type: actionTypes.SET_LANGUAGE,
    payload: {
      currentLanguage: language
    }
  };
};

export const setSideMenu = isOpen => {
  return {
    type: actionTypes.SET_SIDEMENU,
    payload: {
      isOpen
    }
  };
};

export const setLoginData = (jwt, profile) => {
  return {
    type: actionTypes.SET_LOGIN,
    payload: {
      jwt,
      profile
    }
  };
};

export const setPage = page => {
  return {
    type: actionTypes.SET_PAGE,
    payload: {
      page
    }
  };
};

export const setTrace = trace => {
  return {
    type: actionTypes.SET_TRACE,
    payload: {
      trace
    }
  }
}

export const setTraceData = data => {
  return {
    type: actionTypes.SET_TRACEDATA,
    payload: {
      trace: data
    }
  };
};

export const setLoginModal = (isOpen, loginFun) => {
  return {
    type: actionTypes.SET_LOGIN_MODAL,
    payload: {
      isOpen,
      loginFun,
    }
  };
};
export const setLogoutModal = isOpen => {
  return {
    type: actionTypes.SET_LOGOUT_MODAL,
    payload: {
      isOpen
    }
  };
};
export const setConfirmModal = (isOpen, message = '') => {
  return {
    type: actionTypes.SET_CONFIRM_MODAL,
    payload: {
      isOpen,
      message
    }
  };
};