export const justifyNameToPath = str => str.replace(/\s+/g, "-").toLowerCase();
export const justifyProductName = name => name.split("-").map(s => s.substring(0, 1).toUpperCase() + s.substring(1, s.length)).join(" ").replace("Wicanvas", "WiCanvas");
export const justifyProductToImageName = product => product.replace(/-/g, "");

export const justifyIndoorProductName = name => name.split("-").map(s => s.substring(0, 1).toUpperCase() + s.substring(1, s.length)).join("").replace("Wicanvas", "WiCanvas");
export const justifyAccessoryProductName = name => {
    switch (name) {
        case ("hanging"):
            return "Hanging";
        case ("wicanvas-5-dual-stand"):
            return "WiCanvas5dualstand";
        case ("max-75-stand"):
            return "Max75stand";
        default:
            return name;
    };
};
export const justifyOfficeProductName = name => name.split("-").map(s => s.substring(0, 1).toUpperCase() + s.substring(1, s.length)).join("").replace("Wicanvas", "WiCanvas");
export const justifyOutdoorProductName = name => name.substring(0, 1).toUpperCase() + name.substring(1, name.length);
export const justifyWallProductName = name => {
    switch (name) {
        case ("video-wall"):
            return "VideoWall";
        case ("video-wall-with-cms-embedded"):
            return "VideoWallWithCMSEmbedded";
        case ("video-wall-for-meeting-room"):
            return "VideoWallForMeetingRoom";
        case ("led-wall"):
            return "LEDWall";
        default:
            return name;
    };
};

export const whiteSpaceForceBreak = txt => txt.split(" ").join("\n");