import decode from "jwt-decode";
import AESUtil from "./AESUtil";


export default class AuthManager {
  constructor() {
    // Set to DEBUG mode
    this.DEBUG = true;
    //login verify and get the jwt
    this.refer = "https://ops.wicanvas.net";
  }

  /* Status check after fetch the API or http response */
  checkStatus = response => {
    // raises an error in case response status is not a success
    // console.log(response);
    let error = "";
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    }

    if (response.status === 400) {
      return response;
    }

    error = new Error(response.statusText);
    error.response = response;
    throw error;
  };

  fetch = (url, options) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "text/json"
    };

    return fetch(url, {
      headers,
      ...options
    })
      .then(this.checkStatus)
      .then(response => response.json());
  };

  /**
   * login
   * @param email string
   * @param password string
   * @returns Promise.resolve -> and you should catch it in asynchronize callback
   */
  userlogin = (email, password) => {
    const normalway = () => {
      const encryptPassword = AESUtil.encrypt(password);
      return this.fetch(`${this.refer}/web/externalLogin/loginUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email,
          password: encryptPassword
        })
      }).then(res => {
        // this.DEBUG = false
        return Promise.resolve(res);
      });
    }

    if (this.DEBUG) {
      console.log("enter debug API");
      return this.fetch(
        "https://fy1et92nuj.execute-api.us-west-2.amazonaws.com/prod/DynamoDBManager",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email,
            password
          })
        }
      ).then(res => {
        console.log(res);
        if (res.token != null) {
          this.setToken(res.token);
          this.setProfile(res.profile);
        } else {
          return normalway()
        }
        return Promise.resolve(res);
      });
    } else {
      return normalway()
    }
  };

  /**
   * logout
   * @param jwt string
   * @returns Promise.resolve -> and you should catch it in asynchronize callback
   */
  userlogout = () => {
    const jwt = this.getToken();

    if (this.DEBUG) {
      //debug mode
      return Promise.resolve('debug mode')
    } else {
      return this.fetch(`${this.refer}/web/externalUser/logout`, {
        method: "GET",
        headers: {
          Authorization: jwt,
          "Content-Type": "application/json",
          Referer: "https://ops.wicanvas.net"
        }
      }).then(res => {
        return Promise.resolve(res);
      });
    }
  };

  /**
   * 
   * @param {string} email member email
   * @returns  
   */

  sendForgetPasswordMail = email => {
    return this.fetch(`${this.refer}/web/noLogin/sendForgetPasswordMail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Referer: "https://ops.wicanvas.net"
      },
      body: JSON.stringify({ email })
    }).then(res => {
      return Promise.resolve(res);
    });
  };

  /**
   * fetchAPIProfile
   * @param jwt string
   * @returns Promise.resolve -> and you should catch it in asynchronize callback
   */
  getUserProfileFromJWT = jwt =>
    this.fetch(`${this.refer}/web/externalUser/getUserProfile`, {
      method: "GET",
      headers: {
        Authorization: jwt,
        "Content-Type": "application/json",
        Referer: "https://ops.wicanvas.net"
      }
    }).then(res => {
      // console.log(res);
      if (res.role_name != null) {
        // avoid the token is undefine case
        this.setToken(jwt);
        // this.setProfile(res.profile);
        if (res.web_user_name.split(" ").length >= 3) {
          this.setProfile(
            `${res.web_user_name.split(" ")[0]} ${
            res.web_user_name.split(" ")[1]
            }`
          );
        } else {
          this.setProfile(res.web_user_name);
        }
      }
      return Promise.resolve(res);
    });

  setToken = jwt => {
    sessionStorage.setItem("jwt", jwt);
  };
  getToken = () => sessionStorage.getItem("jwt");
  setProfile = profile => {
    sessionStorage.setItem("profile", profile);
  };
  getProfile = () => sessionStorage.getItem("profile");
  removeToken = () => sessionStorage.removeItem("jwt");
  removeProfile = () => sessionStorage.removeItem("profile");

  isLogin = () => {
    const jwt = this.getToken();
    return !!jwt && !this.isTokenExpired(jwt);
  };

  isTokenExpired = token => {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // if token expired -> clear the jwt data in session storage
        this.getProfile() != null && this.removeProfile();
        this.getToken() != null && this.removeToken();
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };
}
