import React from "react";
import ResponsiveContainer from "./Responsive/ResponsiveContainer";
import withAuth from "../auth/withAuth";
import "./App.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTrace, setSideMenu, setLanguage, setPage, setLoginModal, setLogoutModal, setConfirmModal } from '../actions';
import { traceDispatch } from '../traceDispatch/traceDispatch.js';

const App = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    const { history } = props
    const path = history.location.pathname;
    const search = history.location.search;
    let urlInfo = {}
    if (search) {
      search.replace("?", "").split("&").forEach(i => {
        let splitIndex = i.indexOf("=")
        urlInfo[i.substring(0, splitIndex)] = i.substring(splitIndex + 1, i.length)
      })
    }
    let startTime = new Date().getTime();
    if (urlInfo.id) {
      localStorage.setItem("traceId", urlInfo.id)
      let data = {
        traceId: urlInfo.id,
        startTime,
        trace: [{ path, timeStamp: startTime }]
      }
      traceDispatch(data)
    }

    history.listen(location => {
      let { pathname } = location;
      // add the trace info when client change page
      dispatch(setTrace({ path: pathname, timeStamp: new Date().getTime() }));

      // auto close sidebarMenu
      dispatch(setSideMenu(false));

      // auto close modals
      dispatch(setLoginModal(false));
      dispatch(setLogoutModal(false));
      dispatch(setConfirmModal(false));
      let lang = "en-GB";
      const pathList = history.location.pathname.split('/');
      switch (pathList[1]) {
        case ("jp"):
          lang = "ja-JP";
          break;
        default:
      };
      let page = lang === "en-GB" ? pathList[1] : pathList[2];
      if (page === 'products') {
        page = pathList[pathList.indexOf(page) + 1];
      };
      dispatch(setPage(page));
      dispatch(setLanguage(lang));
    });
  }, []);
  return <ResponsiveContainer />;
}

export default withAuth(App);
