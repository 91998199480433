import React, { useEffect } from "react";
import { Responsive } from "semantic-ui-react";
import { connect } from "react-redux";
import EntryContainer from "./EntryContainer";
import robot from './robot';

const ResponsiveContainer = props => {
  const { languageTable, currentPage, currentLanguage } = props;
  const getWidth = () => {
    const isSSR = typeof window === "undefined";
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
  };
  useEffect(() => {
    if (getWidth > 768) robot();
  }, [])
  return (
    <div onContextMenu={e => e.preventDefault()}>
      <EntryContainer
        getWidth={getWidth}
        lang={languageTable}
        currentLang={currentLanguage}
        currentPage={currentPage}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  languageTable: state.langAction.languageTable,
  currentLanguage: state.langAction.currentLanguage,
  currentPage: state.pageAction.page,
});

export default connect(
  mapStateToProps
)(ResponsiveContainer);
