import crypto from "crypto";

const key = "wiwall.wistron.c"; // 加密key, 128 byte
const iv = "wiwallrd@wistron"; // 向量, 128 byte

export default class AESUtil {
  /**
   * encrypt
   * @param data
   * @returns string
   */
  static encrypt(data) {
    const cipher = crypto.createCipheriv("aes-128-cbc", key, iv);
    let crypted = cipher.update(data, "utf8", "binary");
    crypted += cipher.final("binary");
    crypted = Buffer.from(crypted, "binary").toString("base64");
    return crypted;
  }

  /**
   * decrtpy
   * @param crypted string
   * @returns string
   */
  static decrypt(crypted) {
    const newCrypted = Buffer.from(crypted, "base64").toString("binary");
    const decipher = crypto.createDecipheriv("aes-128-cbc", key, iv);
    let decoded = decipher.update(newCrypted, "binary", "utf8");
    decoded += decipher.final("utf8");
    return decoded;
  }
}
