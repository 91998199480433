import React from 'react';
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
const MenuList = props => {
    const { currentPage, lang, setPage, langPath, menuItems, isMobile, handleSidebarHide } = props;
    const onClick = path => {
        setPage(path);
        if (isMobile) handleSidebarHide();
    };
    const itemStyle = {
        padding: !isMobile ? ".78571429em .3em" : "auto"
    }
    return (
        <>
            {
                menuItems.map((item, index) => {
                    let { name, jpName, path, isProduct } = item;
                    let itemPath = langPath + path
                    if (isProduct) {
                        itemPath = `${langPath}products/${path}`
                    };
                    const pathList = window.location.pathname.split('/');
                    let isInProductsPage = isProduct && pathList.pop() !== "products";
                    let isActive = path === currentPage;
                    let isNotLink = false;
                    let languageIndex = index + 1;
                    if (!isMobile && languageIndex > 5) {
                        languageIndex += 1;
                    };
                    return (
                        <Menu.Item
                            style={itemStyle}
                            fitted={!isMobile}
                            key={index}
                            as={!isNotLink ? Link : 'a'}
                            to={!isNotLink ? itemPath : undefined}
                            name={name}
                            onClick={() => !isNotLink ? onClick(path) : undefined}
                            active={isActive}
                        >
                            {langPath === '/' ? name : jpName}
                        </Menu.Item>
                    )
                })
            }
        </>
    )
};

export default MenuList;