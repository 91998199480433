import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setPage } from '../../../actions';
import "./Footer.css";

const Footer = props => {
  const { lang } = props;
  const langPathList = window.location.pathname.split("/");
  const langPath = langPathList[1] === "jp" ? "/jp/" : "/";
  const dispatch = useDispatch();
  const linkArr = ["copyright", "privacy", "contact"];
  return (
    <div className="footer-main">
      <div className="copyright-field">{lang.get("footer-right-reserved")}</div>
      <div className="footer-link">
        {
          linkArr.map((link, index) => (
            <Link key={index} to={langPath + link} onClick={() => dispatch(setPage(link))}>{lang.get(`footer-${link}`)}</Link>
          ))
        }
      </div>
    </div>
  );
}

export default Footer;
