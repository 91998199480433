import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./PageController.css";
import routes from './routes';
import JsonLd from "./JsonLd/JsonLd";

class MobilePageController extends React.Component {
  render() {
    return (
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={450} classNames="fade">
              <Suspense fallback={<div style={{ height: "100vh" }} />}>
                <Switch location={location}>
                  {
                    routes.map((i, n) => <Route key={n} exact={i.exact} path={i.path} render={() => (
                      <>
                        <JsonLd path={window.location.pathname} />
                        <i.mobileComponent openConfirmModal={this.props.openConfirmModal} />
                      </>
                    )} />)
                  }
                </Switch>
              </Suspense>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    );
  }
}

export default MobilePageController;
