import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Route, withRouter, Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import "./index.css";
import App from "./components/App";
// import serviceWorker from "./serviceWorker";
import history from "./History";
import rootReducer from "./reducers";
import "semantic-ui-css/semantic.min.css";

// PM Task List
const TaskList = lazy(() => import('./components/Responsive/TaskList'));
// Sales Tools
const Tracer = lazy(() => import('./components/Responsive/Tracer'));

const store = createStore(rootReducer, composeWithDevTools());

/**
 * Note:
 * 1. 
 * 2. 
 * 
 * 
 * TODO:
 * 1. =
 * 
**/



class Root extends React.Component {
  stste = {
    user: null
  };
  render() {
    return (
      <Suspense fallback={<div style={{ height: "100vh" }} />}>
        <Switch>
          <Route path="/TaskList" component={TaskList} />
          <Route path="/tracer" component={Tracer} />
          <Route path="/" component={App} />
        </Switch>
      </Suspense>
    );
  }
}

const RootWithRouter = withRouter(Root);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <RootWithRouter />
    </Router>
  </Provider>,
  document.getElementById("root")
);
// serviceWorker.unregister();
