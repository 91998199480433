/* Language action */
export const SET_LANGUAGE = "SET_LANGUAGE";

/* SideMenu action */
export const SET_SIDEMENU = "SET_SIDEMENU";

/* User authorization action */
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";

/* Page action */
export const SET_PAGE = "SET_PAGE";

/* modal action */
export const SET_LOGIN_MODAL = "SET_LOGIN_MODAL";
export const SET_LOGOUT_MODAL = "SET_LOGOUT_MODAL";
export const SET_CONFIRM_MODAL = "SET_CONFIRM_MODAL";

// Trace action
export const SET_TRACEID = "SET_TRACEID";
export const SET_TRACE = "SET_TRACE";

// Sales tools action
export const SET_TRACEDATA = "SET_TRACEDATA";