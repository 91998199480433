import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Organization, Website, Breadcrumb } from '../../../constants/JsonLd.json';
import productsData from '../../../constants/WiCanvasProductSeriesNew.json';
import { langs } from '../routes';
import { justifyProductName, justifyWallProductName, justifyNameToPath } from '../../../utils';

const JsonLd = ({ path }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    let pathList = path.split('/');
    let langData = pathList[1].length === 2 ? langs.find(i => i.langPath === `/${pathList[1]}/`) : langs[0];
    let { langPath, langTableName: lang } = langData;
    let htmlAttributes = { lang, "xml:lang": lang };
    // define which page it is
    let query = pathList[1].length === 2 ? pathList[2] : pathList[1];

    const domain = "https://wicanvas.wistron.com";

    // add breadcrumbs schema.org
    let homepageUrl = [langPath];
    let breadcrumb = JSON.parse(JSON.stringify(Breadcrumb.default));


    // if it isn't hompage add breadcrumb
    if (homepageUrl.every(i => i !== path)) {
        breadcrumb.itemListElement.push(Breadcrumb[query]);
    };

    breadcrumb.itemListElement = breadcrumb.itemListElement.map((i, n) => {
        if (i) {
            i.item["@id"] = domain + langPath;
            i.item["url"] = domain + langPath;
            if (n > 0) {
                i.item["@id"] += query;
                i.item["url"] += query;
            };
        }
        return i;
    });

    if (langPath !== '/') {
        pathList.splice(1, 1);
    };




    let productName;
    if (query === 'products' && pathList.length > 2) {
        // product page

        let productType = pathList[2];
        if (productType.indexOf("wall") > -1) {
            productType = justifyWallProductName(productType).replace("Wall", " Wall");
        } else if (productType === 'cms') {
            productType = productType.toUpperCase();
        } else if (productType.indexOf("kiosk-43") > -1) {
            productType = 'Kiosk 43';
        } else {
            productType = justifyProductName(productType);
        };

        let keys = Object.keys(productsData[productType]);
        productName = productType;
        keys.forEach(key => {
            productsData[productType][key].other.flat().forEach(name => {
                if (justifyNameToPath(name) === pathList[4]) {
                    productName = name;
                }
            })
        });

        let isNotDigitalSignage = ['OfficeWireless', 'WiBox'];
        let name = isNotDigitalSignage.some(i => i === productName)
            ? `${productName} - WiCanvas`
            : `Digital Signage ${productName} - WiCanvas`;
        breadcrumb.itemListElement.push({
            "@type": "ListItem",
            "position": 2,
            "item": {
                "@id": domain + path,
                name,
                "url": domain + path
            }
        })
    } else if (query === 'support' && pathList.length > 2) {
        // support page

        let pageType = pathList[2];
        if (Breadcrumb[pageType]) {
            breadcrumb.itemListElement.push(Breadcrumb[pageType]);
        };
    };

    // page title
    const title = (productName = 'WiCanvas') => <title>Digital Signage | Professional Display | {productName}</title>
    const canonical = (path = '') => <link rel="canonical" href={domain + path} />
    const alternate = () => {
        let defaultPath;
        let alternateArray = langs.map((i, n) => {
            let href = path === '' && i.langPath === '/' ? domain : (domain + path).replace(domain + langPath, domain + i.langPath);
            if (i.langPath === '/') {
                defaultPath = href;
            };
            return (
                <link key={"alternate" + n} rel="alternate" hreflang={i.langTableName}
                    href={href} />
            )
        })
        alternateArray.push(<link key={"alternate" + alternateArray.length} rel="alternate" hreflang="x-default" href={defaultPath} />)
        return alternateArray;
    };

    useEffect(() => {
        // delete the orgin html tag
        if (!isLoaded) {
            let orginLink = document.querySelector('link[rel=canonical]:not([data-react-helmet])')
            if (orginLink)
                orginLink.remove()
            let orginJsonLd = document.querySelectorAll('script[type]:not([src]):not([data-react-helmet])')
            orginJsonLd.forEach(el => el.remove())
            setIsLoaded(true)
        }
    }, [isLoaded])


    return homepageUrl.some(i => i === path)
        ? (
            <Helmet htmlAttributes={htmlAttributes}>
                {title()}
                {canonical()}
                {alternate()}
                <script type="application/ld+json">{JSON.stringify(Organization)}</script>
                <script type="application/ld+json">{JSON.stringify(Website)}</script>
            </Helmet>
        )
        : (
            <Helmet htmlAttributes={htmlAttributes}>
                {title(productName)}
                {canonical(path)}
                {alternate()}
                <script type="application/ld+json" >{JSON.stringify(breadcrumb)}</script>
            </Helmet>
        )
}
// only rerender when change the page
const areEqual = (preProps, nextProps) => preProps.path === nextProps.path

export default memo(JsonLd, areEqual);