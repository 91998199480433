import React from 'react';
import { Dropdown, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../../../actions';
import memberIcon from '../../../images/header/header_member_icon.png';
import './MemberDropDown.css';
import routes from '../routes';
import AuthManager from '../../../auth/AuthManager';

const Auth = new AuthManager();
const MemberDropDown = props => {
    const { isMobile, openLogoutModal, profile, logoutTxt, lang, langs, setCurrentLangData, handleSidebarHide } = props;
    const dispatch = useDispatch();
    const store = useSelector(state => state.traceAction);
    const currentPath = store.trace[store.trace.length - 1];
    const langPath = lang.get("lang-path");
    let deviceType = isMobile ? "mobile" : "desktop";
    const image = <Image className="desktop_member_icon" src={memberIcon} />
    const handleChangeLang = ({ isActive, langData }) => {
        if (!isActive) {
            setCurrentLangData(langData);
            // setLanguage(langData.langTableName)
            if (isMobile) handleSidebarHide();
        };
    };
    return (
        <Dropdown
            className="item desktop__member__menu"
            style={{ padding: "0 5px", width: "auto" }}
            trigger={isMobile ? <span>Language</span> : image}
            icon={null}
            direction="left"
        >
            {Auth.isLogin() ? (
                <Dropdown.Menu
                    className={`${deviceType}__member__dropdown`}
                    style={{ backgroundColor: isMobile ? "transparent" : "#FFF" }}
                >
                    {/* <Dropdown.Header content={profile} /> */}
                    <Dropdown.Item
                        style={isMobile ? { display: 'none' } : {}}
                        as={Link}
                        to={`${langPath}contact`}
                        onClick={() => dispatch(setPage("contact"))}
                    >
                        {lang.get("form")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {
                        langs.map((langData, index) => {
                            let { langName, langPath } = langData;
                            let isActive = lang.get("lang-name") === langName;
                            let to = currentPath.path.replace(lang.get("lang-path"), langPath);
                            if (!routes.some(i => i.path === to)) {
                                to = langPath;
                            };
                            return (
                                <Dropdown.Item
                                    key={index}
                                    active={isActive}
                                    className={(isActive && isMobile) ? "__mobile_lang_text" : undefined}
                                    style={isMobile ? { color: "white" } : { textAlign: "center" }}
                                    as={isActive ? 'a' : Link}
                                    to={isActive ? undefined : to}
                                    onClick={() => handleChangeLang({ isActive, langData })}
                                >
                                    {langName}
                                </Dropdown.Item>
                            )
                        })
                    }
                    <Dropdown.Divider />
                    <Dropdown.Item
                        style={{ textAlign: "center" }}
                        as='a'
                        onClick={openLogoutModal}
                    >
                        {logoutTxt}
                    </Dropdown.Item>
                </Dropdown.Menu>
            ) : (
                <Dropdown.Menu
                    className={`${deviceType}__lang__dropdown`}
                    style={{ backgroundColor: isMobile ? "transparent" : "#FFF" }}
                >
                    <Dropdown.Item
                        style={isMobile ? { display: 'none' } : {}}
                        as={Link}
                        to={`${langPath}contact`}
                        onClick={() => dispatch(setPage("contact"))}
                    >
                        {lang.get("form")}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {
                        langs.map((langData, index) => {
                            let { langName, langPath } = langData;
                            let isActive = lang.get("lang-name") === langName;
                            let to = currentPath.path.replace(lang.get("lang-path"), langPath);
                            if (!routes.some(i => i.path === to)) {
                                to = langPath;
                            };
                            return (
                                <Dropdown.Item
                                    key={index}
                                    active={isActive}
                                    className={(isActive && isMobile) ? "__mobile_lang_text" : undefined}
                                    style={isMobile ? { color: "white" } : { textAlign: "center" }}
                                    as={isActive ? 'a' : Link}
                                    to={isActive ? undefined : to}
                                    onClick={() => handleChangeLang({ isActive, langData })}
                                >
                                    {langName}
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            )}
        </Dropdown>
    );
};

export default MemberDropDown;