import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSideMenu, setLoginModal, setLogoutModal, setConfirmModal } from '../../actions';
import { Responsive, Sidebar } from "semantic-ui-react";
import DesktopPageController from "./DesktopPageController";
import MobilePageController from "./MobilePageController";
import Header from './Header/Header';
import Footer from "./Footer/Footer";

import LoginModal from "./Modal/LoginModal";
import LogoutModal from "./Modal/LogoutModal";
import ConfirmModal from "./Modal/ConfirmModal";
import './Modal/Modal.css';
import AuthManager from "../../auth/AuthManager";

const EntryContainer = props => {
  const { getWidth, lang, currentPage, currentLang } = props;
  const Auth = new AuthManager();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const { isOpen } = useSelector(state => state.sideMenuAction);
  const { isLoginModalOpen, isLogoutModalOpen, isConfirmModalOpen, confirmModalMessage } = useSelector(state => state.modalAction);
  const dispatch = useDispatch();

  useEffect(() => {
    window.onresize = () => setDeviceWidth(window.innerWidth);
    window.scrollTo(0, 0);
    if (window.location.search === '?to=support') {
      // console.log(currentLang);
      if (!Auth.isLogin()) {
        dispatch(setLoginModal(true))
      } else {
        window.location.href = `${currentLang === 'ja-JP' ? "/jp/" : "/"}support`;
      };
    };
  }, []);

  const handleSidebarHide = () => dispatch(setSideMenu(false));

  return (
    <>
      <Header
        isMobile={deviceWidth < 1120}
        lang={lang}
        currentPage={currentPage}
        openLoginModal={() => dispatch(setLoginModal(true))}
        openLogoutModal={() => dispatch(setLogoutModal(true))}
        handleSidebarHide={handleSidebarHide}
        handleToggle={open => dispatch(setSideMenu(open))}
        isSidebarOpened={isOpen}
      />
      {/* render pageController according to width  */}
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={deviceWidth < 1120 ? isOpen : false} onClick={handleSidebarHide}>
            <div className="desktop_entry_body">
              <DesktopPageController openConfirmModal={message => dispatch(setConfirmModal(true, message))} />
              <Footer lang={lang} />
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
      <Responsive getWidth={getWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar.Pushable>
          <Sidebar.Pusher dimmed={isOpen} onClick={handleSidebarHide}>
            <MobilePageController openConfirmModal={message => dispatch(setConfirmModal(true, message))} />
            <Footer lang={lang} />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
      <LoginModal
        open={isLoginModalOpen}
        onClose={() => dispatch(setLoginModal(false))}
        lang={lang}
        Auth={Auth}
      />
      <LogoutModal
        open={isLogoutModalOpen}
        onClose={() => dispatch(setLogoutModal(false))}
        lang={lang}
        Auth={Auth}
      />
      <ConfirmModal
        open={isConfirmModalOpen}
        onClose={() => dispatch(setConfirmModal(false))}
        lang={lang}
        message={confirmModalMessage}
      />
    </>
  );
}

export default EntryContainer;
