import React, { useEffect } from "react";
import AuthManager from "../auth/AuthManager";
import { useDispatch } from "react-redux";
import { setLoginData } from "../actions/index";
import routes, { langs } from '../components/Responsive/routes';

const withAuth = WrappedComponent => {
  const Auth = new AuthManager();
  const C = props => {
    const dispatch = useDispatch();
    useEffect(() => {
      const { history } = props;
      const path = history.location.pathname;

      // Redirect to Homepage when refreshing website when the path isn't in the routes or the user doesn't have authority
      // console.log(routes);
      let homePath = "/";
      langs.forEach(langData => {
        let { langPath } = langData;
        if (path.indexOf(langPath) === 0) {
          homePath = langPath;
        };
      });

      if (!routes.some(i => i.path === path)) {
        history.replace(homePath);
      } else if (!Auth.isLogin()) {
        dispatch(setLoginData("", ""));
        let page = path.split("/").pop();
        if (page === "support" || page === "scenario") {
          const newHistory = path.replace(/support[\S]*/, "");
          history.replace(newHistory);
        };
      } else {
        dispatch(setLoginData(Auth.getToken(), Auth.getProfile()));
      };
    }, []);
    return <WrappedComponent {...props} />;
  }
  return C;
};

export default withAuth;
