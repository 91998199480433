export const traceDispatch = data => {
    const url = "https://sz39k3k4mj.execute-api.us-east-1.amazonaws.com/dev/writetrace";
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };
    // IE
    if (navigator.userAgent.indexOf("Chrome") < 0 && navigator.userAgent.indexOf("Safari") < 0) {
        try {
            let http = new XMLHttpRequest()
            http.open("POST", url);
            http.setRequestHeader("Content-Type", "application/json");
            http.onload = (res) => {
                console.log(http.response);
            }
            http.send(JSON.stringify(data))
        } catch (e) {
            console.log(e);
        }
    } else {
        fetch(url, {
            headers,
            method: "POST",
            body: JSON.stringify(data),
            keepalive: true
        }).then(i => i.json())
            .then(res => {
                try {
                    if (res.statusCode === 201) {
                        console.log(res);
                        localStorage.setItem("uuid", JSON.parse(res.body)["id"])
                    }
                } catch (error) {
                    console.log(error);
                }
            })
    }
}