import React from 'react';
import { Dropdown, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import languageIcon from '../../../images/header/header_language_icon.png';
import { useSelector } from 'react-redux';
import routes from '../routes';
const LangDropDown = props => {
    const { setCurrentLangData, isMobile, handleSidebarHide, langs, lang, setLanguage } = props;
    const store = useSelector(state => state.traceAction);
    const currentPath = store.trace[store.trace.length - 1];
    let deviceType = isMobile ? "mobile" : "desktop";
    const handleChangeLang = ({ isActive, langData }) => {
        if (!isActive) {
            setCurrentLangData(langData);
            // setLanguage(langData.langTableName)
            if (isMobile) handleSidebarHide();
        };
    };
    const image = <Image className="desktop_lang_icon" src={languageIcon} />
    return (
        <Dropdown
            className={!isMobile ? "item desktop__lang__menu" : undefined}
            style={isMobile ? { width: "100%" } : { padding: "0", width: "auto" }}
            trigger={isMobile ? <span>Language</span> : image}
            icon={null}
        >
            <Dropdown.Menu
                className={`${deviceType}__lang__dropdown`}
                style={{ backgroundColor: isMobile ? "transparent" : "#FFF" }}
            >
                {
                    langs.map((langData, index) => {
                        let { langName, langPath } = langData;
                        let isActive = lang.get("lang-name") === langName;
                        let to = currentPath.path.replace(lang.get("lang-path"), langPath);
                        if (!routes.some(i => i.path === to)) {
                            to = langPath;
                        };
                        return (
                            <Dropdown.Item
                                key={index}
                                active={isActive}
                                className={(isActive && isMobile) ? "__mobile_lang_text" : undefined}
                                style={isMobile ? { color: "white" } : { textAlign: "center" }}
                                as={isActive ? 'a' : Link}
                                to={isActive ? undefined : to}
                                onClick={() => handleChangeLang({ isActive, langData })}
                            >
                                {langName}
                            </Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default LangDropDown;