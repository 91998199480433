import React from "react";
import { Modal } from 'semantic-ui-react';
import "./LogoutModal.css";

const LogoutModal = props => {
  const { open, onClose, lang, Auth } = props;
  const handleLogout = async e => {
    await Auth.userlogout().catch(err => console.log(err));
    Auth.removeProfile();
    Auth.removeToken();
    window.location.reload();
  };
  return (
    <Modal
      centered={false}
      open={open}
      onClose={onClose}
      style={{
        maxWidth: "500px"
      }}
    >
      <div className="__logout_modal __custom_modal">
        <div className="__logout_modal_text">
          <p>{lang.get("Logout-descript1")}</p>
          <p>{lang.get("Logout-descript2")}</p>
        </div>
        <button
          className="__login_modal_form_button orangeButton"
          onClick={handleLogout}
          type="button"
        >
          {lang.get("Logout-btn-txt")}
        </button>
      </div>
    </Modal>
  );
};

export default LogoutModal;
