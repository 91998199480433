import React from "react";
import { Modal } from 'semantic-ui-react';
import "./ConfirmModal.css";

const ConfirmModal = props => {
    const { open, onClose, message, lang } = props;
    return (
        <Modal
            centered={false}
            open={open}
            onClose={onClose}
            style={{
                maxWidth: "460px"
            }}
        >
            <div className="__confirm_modal __custom_modal">
                <div className="__modal_text">
                    <p>{message}</p>
                </div>
                <button
                    className="orangeButton"
                    onClick={onClose}
                    type="button"
                >
                    {lang.get("Confirm-btn-txt")}
                </button>
            </div>
        </ Modal>
    );
};

export default ConfirmModal;
