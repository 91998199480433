import Immutable from "immutable";
import languageTable from './languageTable';

// initialize page and lang by path

let lang = "en-GB";

const pathList = window.location.pathname.split('/');
switch (pathList[1]) {
  case ("jp"):
    lang = "ja-JP";
    break;
  default:
};
let page = lang === "en-GB" ? pathList[1] : pathList[2];
if (page === 'products') {
  page = pathList[pathList.indexOf(page) + 1];
};

export const traceState = {
  trace: [{
    path: window.location.pathname,
    timeStamp: new Date().getTime(),
  },]
};

export const pageState = { page };

export const langState = Immutable.fromJS({
  lang,
  langs: languageTable
});

