// lazy load
import { lazy } from 'react';
//products
import productsData from '../../../constants/WiCanvasProductSeriesNew.json';

import { justifyNameToPath } from '../../../utils';

// desktop
const HomeContainer = lazy(() => import("../DesktopVersion/Home/HomeContainer.jsx"));
const ContactContainer = lazy(() => import("../DesktopVersion/Contact/ContactContainer.jsx"));
const VideoContainer = lazy(() => import('../DesktopVersion/VideoPage/VideoContainer.jsx'));
const ProductsIndex = lazy(() => import('../DesktopVersion/Products/ProdutsIndex.jsx'));
const IndoorProducts = lazy(() => import('../DesktopVersion/Products/IndoorProducts/IndoorProducts.jsx'));
const LCDBorderLessProducts = lazy(() => import('../DesktopVersion/Products/LCDBorderLessProducts/LCDBorderLessProducts.jsx'));
const WallProducts = lazy(() => import('../DesktopVersion/Products/WallProducts/WallProducts.jsx'));
const OfficeProducts = lazy(() => import('../DesktopVersion/Products/OfficeProducts/OfficeProducts.jsx'));
const OutdoorProducts = lazy(() => import('../DesktopVersion/Products/OutdoorProducts/OutdoorProducts.jsx'));
const AccessoryProducts = lazy(() => import('../DesktopVersion/Products/AccessoryProducts/AccessoryProducts.jsx'));
const CMSProducts = lazy(() => import('../DesktopVersion/Products/CMSProducts/CMSProducts.jsx'));
const ScenarioPage = lazy(() => import("../DesktopVersion/Scenario/ScenarioPage.jsx"));
const CasesPage = lazy(() => import("../DesktopVersion/Cases/CasesPage.jsx"));
const KioskProducts = lazy(() => import('../DesktopVersion/Products/KioskProducts/KioskProducts.jsx'));

// mobile
const MobileHomeContainer = lazy(() => import("../MobileVersion/Home/MobileHomeContainer.jsx"));
const MobileAboutUs = lazy(() => import("../MobileVersion/AboutUs/MobileAboutUs.jsx"));
const MobileContactContainer = lazy(() => import("../MobileVersion/Contact/MobileContactContainer.jsx"));
const MobileVideoPage = lazy(() => import('../MobileVersion/VideoPage/MobileVideoContainer.jsx'));
const MobileProductsIndex = lazy(() => import('../MobileVersion/Products/MobileProductsIndex.jsx'));
const MobileIndoorProducts = lazy(() => import('../MobileVersion/Products/IndoorProducts/MobileIndoorProducts.jsx'));
const MobileLCDBorderLessProducts = lazy(() => import('../MobileVersion/Products/LCDBorderLessProducts/MobileLCDBorderLessProducts.jsx'));
const MobileWallProducts = lazy(() => import('../MobileVersion/Products/WallProducts/MobileWallProducts.jsx'));
const MobileOfficeProducts = lazy(() => import('../MobileVersion/Products/OfficeProducts/MobileOfficeProducts.jsx'));
const MobileOutdoorProducts = lazy(() => import('../MobileVersion/Products/OutdoorProducts/MobileOutdoorProducts.jsx'));
const MobileAccessoryProducts = lazy(() => import('../MobileVersion/Products/AccessoryProducts/MobileAccessoryProducts.jsx'));
const MobileCMSProducts = lazy(() => import('../MobileVersion/Products/CMSProducts/MobileCMSProducts.jsx'));
const MobileScenarioPage = lazy(() => import("../MobileVersion/Scenario/MobileScenarioPage.jsx"));
const MobileCasesPage = lazy(() => import("../DesktopVersion/Cases/CasesPage.jsx"));
const MobileKioskProducts = lazy(() => import('../MobileVersion/Products/KioskProducts/MobileKioskProducts.jsx'));

//for both
const SupportPage = lazy(() => import("../support/supportpage.jsx"));
const Copyright = lazy(() => import("../Privacy/Copyright.jsx"));
const Privacy = lazy(() => import("../Privacy/Privacy.jsx"));

// en -> /
// jp => /jp
export const langs = [
    {
        langName: "English",
        langPath: "/",
        langTableName: "en-GB",
    }, {
        langName: "日本語",
        langPath: "/jp/",
        langTableName: "ja-JP",
    }
];
const routes = [
    {
        path: "/",
        deskTopComponent: HomeContainer,
        mobileComponent: MobileHomeContainer,
        exact: true
    },
    {
        path: "/about-us",
        deskTopComponent: HomeContainer,
        mobileComponent: MobileAboutUs,
    },
    {
        path: "/contact",
        deskTopComponent: ContactContainer,
        mobileComponent: MobileContactContainer
    },
    {
        path: "/video",
        deskTopComponent: VideoContainer,
        mobileComponent: MobileVideoPage
    },
    {
        path: "/support",
        deskTopComponent: SupportPage,
        mobileComponent: SupportPage,
        exact: true
    },
    {
        path: "/support/scenario",
        deskTopComponent: ScenarioPage,
        mobileComponent: MobileScenarioPage
    },
    {
        path: "/copyright",
        deskTopComponent: Copyright,
        mobileComponent: Copyright
    },
    {
        path: "/privacy",
        deskTopComponent: Privacy,
        mobileComponent: Privacy
    },
    {
        path: "/cases",
        deskTopComponent: CasesPage,
        mobileComponent: MobileCasesPage
    },
];

let series = Object.keys(productsData);
series.forEach((type, n) => {
    // add product route
    let productIndexPath = `/products/${justifyNameToPath(type)}`;
    let routeData = {
        path: productIndexPath,
        deskTopComponent: ProductsIndex,
        mobileComponent: MobileProductsIndex,
        exact: true
    };
    switch (type) {
        case ("LED Wall"):
            routeData.deskTopComponent = WallProducts;
            routeData.mobileComponent = MobileWallProducts;
            break;
        case ("Borderless"):
            routeData.deskTopComponent = MobileProductsIndex;
            break;
        case ("Kiosk 43"):
            routeData.deskTopComponent = KioskProducts;
            routeData.mobileComponent = MobileKioskProducts;
            break;
        case ("CMS"):
            routeData.deskTopComponent = CMSProducts;
            routeData.mobileComponent = MobileCMSProducts;
            break;
        default:
    };
    routes.push(routeData);
    for (let typeName in productsData[type]) {
        productsData[type][typeName].other.forEach(products => {
            products.forEach(product => {
                let component;
                switch (typeName) {
                    case ("WiCanvas Indoor"):
                        component = {
                            deskTopComponent: IndoorProducts,
                            mobileComponent: MobileIndoorProducts,
                        }
                        break;
                    case ("WiCanvas Office"):
                        if (product === 'WiBox') {
                          component = {
                            deskTopComponent: IndoorProducts,
                            mobileComponent: MobileIndoorProducts,
                          }
                        } else {
                          component = {
                            deskTopComponent: OfficeProducts,
                            mobileComponent: MobileOfficeProducts,
                          }
                        }
                        break;
                    case ("WiCanvas Outdoor"):
                        component = {
                            deskTopComponent: OutdoorProducts,
                            mobileComponent: MobileOutdoorProducts,
                        }
                        break;
                    case ("WiCanvas Accessory"):
                        component = {
                            deskTopComponent: AccessoryProducts,
                            mobileComponent: MobileAccessoryProducts,
                        }
                        break;
                    case ("Video Wall"):
                        component = {
                            deskTopComponent: LCDBorderLessProducts,
                            mobileComponent: MobileLCDBorderLessProducts,
                        }
                        break;
                    default:
                }
                component.path = `${productIndexPath}/${justifyNameToPath(typeName)}/${justifyNameToPath(product)}`
                routes.push(component);
            });
        })
    }
});

// add multilingual routes
langs.forEach(lang => {
    let { langPath } = lang;
    if (langPath !== '/') {
        routes.forEach((component, index) => {
            let c = Object.assign({}, component);
            c.path = langPath + c.path.replace("/", "");
            if (component.path !== '/jp/') {
                routes.push(c);
            };
        });
    };
});

export default routes;